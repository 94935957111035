<template>
  <div class="row">
    <div class="col-12">
      <div class="delete-record">
        <div>
          <p class="anketa"># {{ number + 1 }}</p>
        </div>
        <div class="delete-record-icon" @click="$emit('delete', number)">
          <v-icon size="medium" :hovering="false">delete-table</v-icon> <span class="anketa">Удалить запись</span>
        </div>
      </div>
    </div>
    <div class="col-12">
      <rwm-text-field
              v-model="_kinship"
              label="Родственная связь"
              :error="errors.kinship"
              @input="$emit('removeError')"
              @blur="onBlur('kinship', ...arguments)"
      />
    </div>
    <div class="col-12">
      <rwm-text-field
              v-model="_fio"
              label="Фамилия, имя, отчество"
              :error="errors.fio"
              @input="$emit('removeError')"
              @blur="onBlur('fio', ...arguments)"
      />
    </div>
    <div class="col-3">
      <rwm-date-picker
              v-model="_birthdate"
              label="Дата рождения"
              :error="errors.birthdate"
              @input="$emit('removeError')"
              @blur="onBlur()"
              @isError="setDateError('birthdate', ...arguments)"
      />
    </div>
    <div class="col-3">
      <rwm-text-field
              v-model="_residence"
              label="Место проживания"
              :error="errors.residence"
              @input="$emit('removeError')"
              @blur="onBlur('residence', ...arguments)"
      />
    </div>
    <div class="col-12">
      <p class="anketa">Паспортные данные </p>
    </div>
    <div class="col-3">
      <rwm-text-field
              v-model="_seria"
              v-mask="'####'"
              label="Серия паспорта"
              :error="errors.seria"
              @input="$emit('removeError')"
              @blur="onBlur('seria', ...arguments)"
      />
    </div>
    <div class="col-3">
      <rwm-text-field
              v-model="_num"
              v-mask="'######'"
              label="Номер паспорта"
              :error="errors.num"
              @input="$emit('removeError')"
              @blur="onBlur('num', ...arguments)"
      />
    </div>
    <div class="col-3">
      <rwm-date-picker
              v-model="_issueDate"
              label="Дата выдачи"
              :error="errors.issueDate"
              @input="$emit('removeError')"
              @blur="onBlur()"
              @isError="setDateError('issueDate', ...arguments)"
      />
    </div>
    <div class="col-3">
      <rwm-text-field
              v-model="_department"
              v-mask="'###-###'"
              label="Код подразделения"
              :error="errors.department"
              @input="$emit('removeError')"
              @blur="onBlur('department', ...arguments)"
      />
    </div>
    <div class="col-12">
      <rwm-text-field
              v-model="_issuedBy"
              label="Кем выдан паспорт"
              :error="errors.issuedBy"
              @input="$emit('removeError')"
              @blur="onBlur('issuedBy', ...arguments)"
      />
    </div>
  </div>
</template>

<script>
import RwmTextField from "../../../../../RWMTextField/RwmTextField";
import RwmDatePicker from "../../../../../RWMDatePicker/RwmDatePicker";
import VIcon from "../../../../../icons/VIcon";

export default {
  name: "InformationAboutRelatives",
  components: {VIcon, RwmDatePicker, RwmTextField},
  props: {
    number: [String, Number],
    kinship: String,
    fio: String,
    birthdate: String,
    residence: String,
    seria: String,
    num: String,
    issueDate: String,
    department: String,
    issuedBy: String,
    error: Boolean
  },
  computed: {
    _kinship: {
      get() {
        return this.kinship
      },
      set(val) {
        // this.$emit('setKinship', val)
        this.$emit('input', {category: 'kinship', value: val})
      }
    },
    _fio: {
      get() {
        return this.fio
      },
      set(val) {
        // this.$emit('setFio', val)
        this.$emit('input', {category: 'fio', value: val})
      }
    },
    _birthdate: {
      get() {
        return this.birthdate
      },
      set(val) {
        // this.$emit('setBirthdate', val)
        this.$emit('input', {category: 'birthDate', value: val})
      }
    },
    _residence: {
      get() {
        return this.residence
      },
      set(val) {
        // this.$emit('setResidence', val)
        this.$emit('input', {category: 'residence', value: val})
      }
    },
    _seria: {
      get() {
        return this.seria
      },
      set(val) {
        // this.$emit('setSeria', val)
        this.$emit('input', {category: 'passportSeria', value: val})
      }
    },
    _num: {
      get() {
        return this.num
      },
      set(val) {
        // this.$emit('setNum', val)
        this.$emit('input', {category: 'passportNumber', value: val})
      }
    },
    _issueDate: {
      get() {
        return this.issueDate
      },
      set(val) {
        // this.$emit('setIssueDate', val)
        this.$emit('input', {category: 'passportIssueDate', value: val})
      }
    },
    _department: {
      get() {
        return this.department
      },
      set(val) {
        // this.$emit('setDepartment', val)
        this.$emit('input', {category: 'passportDepartment', value: val})
      }
    },
    _issuedBy: {
      get() {
        return this.issuedBy
      },
      set(val) {
        // this.$emit('setIssuedBy', val)
        this.$emit('input', {category: 'passportIssueBy', value: val})
      }
    },
  },
  data() {
    return {
      errors: {
        kinship: false,
        fio: false,
        birthdate: false,
        residence: false,
        seria: false,
        num: false,
        issueDate: false,
        department: false,
        issuedBy: false,
      }
    }
  },
  watch: {
    error: {
      handler() {
        this.validateFields()
      }
    }
  },
  methods: {
    setDateError(position, isError) {
      this.errors[position] = isError
    },
    onBlur(field = null, event) {
      field && event ? this.errors[field]=event.target.value.length === 0 : ''
      this.$emit('update')
    },
    validateForm(field) {
      this.errors[field] = !this[field] || this[field].length === 0
    },
    validateFields() {
      for (let key in this.errors) {
        if (!this[key] || this[key].trim().length === 0) {
          this.errors[key] = true
        } else {
          this.errors[key] = false
          this.$emit('removeError')
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
  .delete-record {
    display: flex;
    column-gap: 24px;
    align-items: flex-end;
    &-icon {
      display: flex;
      column-gap: 12px;
      align-items: flex-end;
      cursor: pointer;
      @media screen and (min-width: 961px) {
        & span {
          transition: all 300ms;
        }
        &:hover,
        &:hover span {
          color: $RWM-red;
        }
      }
    }
  }
</style>