<template>
  <pod-ft
    :user="getUSER"
    :timer="timer"
    :show-checking="showChecking"
    :prop-goals-of-establishing-a-relationship="
      goalsOfEstablishingARelationship
    "
    :prop-purpose="purpose"
    :prop-purpose-txt="purposeTxt"
    :prop-finpol="finpol"
    :prop-reputation="reputation"
    :prop-sources-of-funds="sourcesOfFunds"
    :prop-citizen-categories-deputat="citizenCategoriesDeputat"
    :prop-citizen-categories-mo="citizenCategoriesMo"
    :prop-citizen-categories-other="citizenCategoriesOther"
    :prop-if-citizen-categories-position="ifCitizenCategoriesPosition"
    :prop-if-citizen-categories-state="ifCitizenCategoriesState"
    :prop-if-citizen-categories-start-date="ifCitizenCategoriesStartDate"
    :prop-if-citizen-categories-end-date="ifCitizenCategoriesEndDate"
    :prop-foreign_politician="foreign_politician"
    :prop-if-foreign_politician-position="ifForeign_politicianPosition"
    :prop-if-foreign_politician-state="ifForeign_politicianState"
    :prop-if-foreign_politician-start-date="ifForeign_politicianStartDate"
    :prop-if-foreign_politician-end-date="ifForeign_politicianEndDate"
    :prop-relative="relative"
    :prop-relations="relations"
    :disabled="timer"
    @submit="onSubmit"
    @sendAgain="onSubmit"
    @timer="setTimer"
    @verified="onVerififed"
    @update="onUpdate"
  />
</template>
<script>
import PodFt from "../../../../components/pages/cabinet/documents/forms/PodFt";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import RWMSendingDocumentsMessageMixin from "../../../../components/mixins/RWMSendingDocumentsMessageMixin";

let model = {
  information_of_business_relations:
    "Приобретение инвестиционных паев, извлечение прибыли", // Сведения о целях установления и предполагаемом характере деловых отношений с ЗАО УК «РВМ Капитал»
  is_extraction_of_profit: true, // Сведения о целях финансово-хозяйственной деятельности (о планируемых операциях) Извлечение прибыли
  other_goal_of_finance_activities: null, // Сведения о целях финансово-хозяйственной деятельности (о планируемых операциях) Иное
  is_good_financial_situation: true, // Сведения о финансовом положении физического лица Удовлетворительное/Неудовлетворительное
  is_good_business_reputation: true, // Сведения о деловой репутации физического лица Удовлетворительное/Неудовлетворительное
  sources_of_origin_money: null, // Сведения об источниках происхождения денежных средств и (или) иного имущества физического лица
  // Блок категорий граждан
  is_public_official: false, // Иностранные публичные должностные лица или российские публичные должностные лица
  is_officials_of_public_international_organizations: false, // Должностные лица публичных международных организаций
  is_another_category_of_citizens: false, // Иные категории граждан
  // если выбрана категория граждан
  another_category_position: null, // Должность и (или) функции
  another_category_country: null, // Государство
  another_category_position_bdate: null, // Дата назначения
  another_category_position_edate: null, // Дата окончания
  // блок высокопоставленная должность
  is_foreign_high_ranking_position: false, // Занимали ли Вы в течение одного года до момента заполнения настоящей анкеты высокопоставленную
  // если выбрана должность
  foreign_high_ranking_position: null, // Должность и (или) функции
  foreign_high_ranking_position_country: null, // Государство
  foreign_high_ranking_position_bdate: null, // Дата назначения
  foreign_high_ranking_position_edate: null, // Дата окончания

  is_adoptive_parent_or_adopted_person: false, // Являетесь ли Вы усыновителем лица (усыновленным лицом), относящегося (относящимся) к категории граждан, указанной в пункте 3 настоящей анкеты
  // блок родственников
  relatives: null,

  is_confirmed_information: false, // Подтверждение информации
};

export default {
  name: "FormPodFt",
  components: { PodFt },
  mixins: [RWMSendingDocumentsMessageMixin],
  computed: {
    ...mapGetters("errorModule", ["getERROR"]),
    ...mapGetters("AuthModule", ["getUSER"]),
    ...mapGetters("DocumentsModule", ["getDRAFT"]),
    goalsOfEstablishingARelationship() {
      return this.getDRAFT
        ? this.getDRAFT.data.information_of_business_relations
        : null;
    },
    purpose() {
      return this.getDRAFT ? this.getDRAFT.data.is_extraction_of_profit : null;
    },
    purposeTxt() {
      return this.getDRAFT
        ? this.getDRAFT.data.other_goal_of_finance_activities
        : null;
    },
    finpol() {
      return this.getDRAFT
        ? this.getDRAFT.data.is_good_financial_situation
        : null;
    },
    reputation() {
      return this.getDRAFT
        ? this.getDRAFT.data.is_good_business_reputation
        : null;
    },
    sourcesOfFunds() {
      return this.getDRAFT ? this.getDRAFT.data.sources_of_origin_money : null;
    },
    citizenCategoriesDeputat() {
      return this.getDRAFT ? this.getDRAFT.data.is_public_official : null;
    },
    citizenCategoriesMo() {
      return this.getDRAFT
        ? this.getDRAFT.data.is_officials_of_public_international_organizations
        : null;
    },
    citizenCategoriesOther() {
      return this.getDRAFT
        ? this.getDRAFT.data.is_another_category_of_citizens
        : null;
    },
    ifCitizenCategoriesPosition() {
      return this.getDRAFT
        ? this.getDRAFT.data.another_category_position
        : null;
    },
    ifCitizenCategoriesState() {
      return this.getDRAFT ? this.getDRAFT.data.another_category_country : null;
    },
    ifCitizenCategoriesStartDate() {
      return this.getDRAFT
        ? this.getDRAFT.data.another_category_position_bdate
        : null;
    },
    ifCitizenCategoriesEndDate() {
      return this.getDRAFT
        ? this.getDRAFT.data.another_category_position_edate
        : null;
    },
    foreign_politician() {
      return this.getDRAFT
        ? this.getDRAFT.data.is_foreign_high_ranking_position
        : null;
    },
    ifForeign_politicianPosition() {
      return this.getDRAFT
        ? this.getDRAFT.data.foreign_high_ranking_position
        : null;
    },
    ifForeign_politicianState() {
      return this.getDRAFT
        ? this.getDRAFT.data.foreign_high_ranking_position_country
        : null;
    },
    ifForeign_politicianStartDate() {
      return this.getDRAFT
        ? this.getDRAFT.data.foreign_high_ranking_position_bdate
        : null;
    },
    ifForeign_politicianEndDate() {
      return this.getDRAFT
        ? this.getDRAFT.data.foreign_high_ranking_position_edate
        : null;
    },
    relative() {
      return this.getDRAFT
        ? this.getDRAFT.data.is_adoptive_parent_or_adopted_person
        : null;
    },
    relations() {
      return this.getDRAFT ? this.getDRAFT.data.relatives : null;
    },
  },
  data() {
    return {
      timer: false,
      showChecking: false,
    };
  },
  methods: {
    ...mapActions("smsModule", ["REQUEST_SMS", "CONFIRM_SMS"]),
    ...mapActions("DocumentsModule", [
      "GET_DOCUMENTS",
      "CREATE_DOCUMENT",
      "UPDATE_DOCUMENT",
    ]),
    setTimer(val) {
      this.timer = val;
    },
    onSubmit() {
      this.REQUEST_SMS({ phone: this.getUSER.phone, doc_id: this.getDRAFT ? this.getDRAFT.id : null, action: 'document_sign'}).then((success) => {
        if (success) {
          this.timer = true;
          this.showChecking = true;
        } else this.getERROR ? this.$toast.error(this.getERROR) : "";
      });
    },
    onVerififed(code) {
      this.CONFIRM_SMS({ phone: this.getUSER.phone, key: code }).then(
        (success) => {
          if (success) {
            let data = { ...this.getDRAFT.data };
            data.date_sign = moment(new Date()).format("X");
            data.signed = true;
            data.is_confirmed_information = true;

            this.UPDATE_DOCUMENT({ id: this.getDRAFT.id, data: data });

            this.show_sending_documents_message();
            setTimeout(() => {
              if (typeof this.$route.query.next !== "undefined" && this.$route.query.next !== "")
                this.$router.push("/cabinet/investment/purchase-of-investment-shares/" + this.$route.query.next + "?next")
              else if (typeof this.$route.query.to !== "undefined")
                this.$router.push("/cabinet/documents?next");
              else
                this.$router.push("/cabinet/documents/POD-FT");
            }, 1000);
          } else {
            this.$toast.error(`${this.getERROR}`);
          }
        }
      );
    },
    createPodft() {
      this.GET_DOCUMENTS("qst-pod_ft").then((resp) => {
        if (resp) {
          if (!this.getDRAFT) {
            this.CREATE_DOCUMENT({ alias: "qst-pod_ft", data: model }).then(
              (response) =>
                !response ? this.$toast.error(`${this.getERROR}`) : ""
            );
          }
        }
      });
    },
    onUpdate(val) {
      this.showChecking = false;
      let key = Object.keys(val)[0];
      let mode = {
        goalsOfEstablishingARelationship: "information_of_business_relations",
        purpose: "is_extraction_of_profit",
        purposeTxt: "other_goal_of_finance_activities",
        finpol: "is_good_financial_situation",
        reputation: "is_good_business_reputation",
        sourcesOfFunds: "sources_of_origin_money",
        citizenCategories: {
          deputat: "is_public_official",
          mo: "is_officials_of_public_international_organizations",
          other: "is_another_category_of_citizens",
        },
        ifCitizenCategories: {
          position: "another_category_position",
          state: "another_category_country",
          startDate: "another_category_position_bdate",
          endDate: "another_category_position_edate",
        },
        foreign_politician: "is_foreign_high_ranking_position",
        ifForeign_politician: {
          position: "foreign_high_ranking_position",
          state: "foreign_high_ranking_position_country",
          startDate: "foreign_high_ranking_position_bdate",
          endDate: "foreign_high_ranking_position_edate",
        },
        relative: "is_adoptive_parent_or_adopted_person",
        relations: "relatives",
      };
      // let data = {...this.getDRAFT.data}
      let data = {};
      if (key === "citizenCategories") {
        let subkey = Object.keys(val[key])[0];
        for (let k in mode[key]) {
          if (mode[key][k] === mode[key][subkey]) {
            data[mode[key][k]] = val[key][k];
          } else data[mode[key][k]] = false;
        }
      }
      if (key === "ifCitizenCategories" || key === "ifForeign_politician") {
        for (let k in val[key]) {
          data[mode[key][k]] = val[key][k];
        }
      } else {
        data[mode[key]] = val[key];
      }
      this.UPDATE_DOCUMENT({ id: this.getDRAFT.id, data: data });
    },
  },
  mounted() {
    model.user = this.getUSER;
    this.createPodft();
  },
};
</script>
