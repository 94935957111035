<template>
  <div class="row">
    <div class="col-12">
      <rwm-text-field
          v-model="_position"
          :error="errors.position"
          label="Укажите должность"
          width="100%"
          @focus="removeError('position')"
          @blur="onBlur('position', ...arguments)"
      />
    </div>
    <div class="col-12">
      <rwm-text-field
          v-model="_state"
          :error="errors.state"
          label="Укажите государство"
          width="100%"
          @focus="removeError('state')"
          @blur="onBlur('state', ...arguments)"
      />
    </div>
    <div class="col-3">
      <rwm-date-picker
          v-model="_startDate"
          :error="errors.startDate"
          label="Дата назначения"
          @blur="onBlur"
          @focus="removeError('startDate')"
          @isError="setDateError('startDate', ...arguments)"
      />
    </div>
    <div class="col-3">
      <rwm-date-picker
          v-model="_endDate"
          :error="errors.endDate"
          label="Дата окончания"
          @blur="onBlur"
          @focus="removeError('endDate')"
          @isError="setDateError('endDate', ...arguments)"
      />
    </div>
  </div>
</template>

<script>
import RwmDatePicker from "../../../../../RWMDatePicker/RwmDatePicker";
import RwmTextField from "../../../../../RWMTextField/RwmTextField";

export default {
  name: "AdditionalFieldsBlock",
  components: {RwmTextField, RwmDatePicker},
  props: {
    position: String,
    state: String,
    startDate: String,
    endDate: String,
    error: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    _position: {
      get() {
        return this.position
      },
      set(val) {
        this.$emit('input', {category: 'position', value: val})
      }
    },
    _state: {
      get() {
        return this.state
      },
      set(val) {
        this.$emit('input', {category: 'state', value: val})
      }
    },
    _startDate: {
      get() {
        return this.startDate
      },
      set(val) {
        if ((this.endDate && this.endDate !== '') && (this.endDate - val < 0)) {
          val = null
          this.setDateError('startDate',true)
          this.$toast.error('Дата назначения не может быть больше даты окончания')
        } else this.errors.startDate = false
        this.$emit('input', {category: 'startDate', value: val})
      }
    },
    _endDate: {
      get() {
        return this.endDate
      },
      set(val) {
        if ((this.startDate && this.startDate !== '') && (val - this.startDate < 0)) {
          val = null
          this.setDateError('endDate',true)
          this.$toast.error('Дата назначения не может быть больше даты окончания')
        } else this.errors.endDate = false
        this.$emit('input', {category: 'endDate', value: val})
      }
    },
  },
  data() {
    return {
      errors: {
        position: false,
        state: false,
        startDate: false,
        endDate: false,
      }
    }
  },
  watch: {
    error: {
      handler(val) {
        console.info('handler',val)
        this.validateFields()
      }
    }
  },
  methods: {
    setDateError(position, isError) {
      this.errors[position] = isError
    },
    onBlur(field = null, event) {
      if (field && event) {
        this.errors[field] = event.target.value.length === 0
      }
      this.$emit('update')
    },

    removeError(key) {
      this.errors[key] = false
    },

    validateFields(){
      for (let key in this.errors) {
        if (!this[key] || this[key].length === 0) {
          this.errors[key] = true
        } else {
          this.errors[key] = false
          this.$emit('removeError')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
