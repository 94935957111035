<template>
    <div class="question">
        <div class="question-mark" @mouseenter="trigger=true" @mouseleave="trigger=false" :id="`questionmark${footnoteNumber}`">
            <svg width="25" height="25" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 31.5C25.4558 31.5 31.5 25.4558 31.5 18C31.5 10.5442 25.4558 4.5 18 4.5C10.5442 4.5 4.5 10.5442 4.5 18C4.5 25.4558 10.5442 31.5 18 31.5Z"
                      fill="#C1CBD6" stroke="#C1CBD6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M18 21.375V20.25C18.89 20.25 19.76 19.9861 20.5001 19.4916C21.2401 18.9971 21.8169 18.2943 22.1575 17.4721C22.4981 16.6498 22.5872 15.745 22.4135 14.8721C22.2399 13.9992 21.8113 13.1974 21.182 12.568C20.5526 11.9387 19.7508 11.5101 18.8779 11.3365C18.005 11.1628 17.1002 11.2519 16.2779 11.5925C15.4557 11.9331 14.7529 12.5099 14.2584 13.2499C13.7639 13.99 13.5 14.86 13.5 15.75"
                      stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M18 27.5625C18.932 27.5625 19.6875 26.807 19.6875 25.875C19.6875 24.943 18.932 24.1875 18 24.1875C17.068 24.1875 16.3125 24.943 16.3125 25.875C16.3125 26.807 17.068 27.5625 18 27.5625Z"
                      fill="white"/>
            </svg>
            <transition name="fade" mode="out-in">
                <rwm-tool-tip v-if="text && trigger" :text="text" :position="aside"/>
                <template v-else/>
            </transition>
        </div>
        <router-link class="question-footnote" :to="`#footnote-${footnoteNumber}`" v-scroll-to="$route.hash">
            {{footnoteNumber}}
        </router-link>
    </div>

</template>

<script>
    import RwmToolTip from "@/components/RWMToolTip/RwmToolTip";

    export default {
        name: "RwmQuestionMark",
        components: {RwmToolTip},
        props: {
            text: {
                type: String,
                default: undefined
            },
            footnoteNumber: [String, Number]
        },
        computed: {
            aside() {
                return (document.getElementById(`questionmark${this.footnoteNumber}`)?.getBoundingClientRect().x - 355) - 400 > 0 ? 'left' : 'right'
            }
        },
        data() {
            return {
                trigger: false,
                element: null
            }
        },
    }
</script>

<style scoped lang="scss">
    .question {
        display: inline-block;
        position: relative;
        &-mark {
            display: none;
            @media screen and (min-width: 961px) {
                display: inline-block;
                cursor: pointer;
                max-width: max-content;
                position: relative;
            }
        }
        &-footnote {
            display: inline-block;
            font-weight: 500;
            font-size: 17px;
            line-height: 30px;
            position: relative;
            top: -15px;
            cursor: pointer;
            text-decoration: none;
            @media screen and (min-width: 961px) {
                display: none;
            }
        }
    }
</style>
