var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('rwm-text-field',{attrs:{"error":_vm.errors.position,"label":"Укажите должность","width":"100%"},on:{"focus":function($event){return _vm.removeError('position')},"blur":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onBlur.apply(void 0, [ 'position' ].concat( argsArray ))}},model:{value:(_vm._position),callback:function ($$v) {_vm._position=$$v},expression:"_position"}})],1),_c('div',{staticClass:"col-12"},[_c('rwm-text-field',{attrs:{"error":_vm.errors.state,"label":"Укажите государство","width":"100%"},on:{"focus":function($event){return _vm.removeError('state')},"blur":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onBlur.apply(void 0, [ 'state' ].concat( argsArray ))}},model:{value:(_vm._state),callback:function ($$v) {_vm._state=$$v},expression:"_state"}})],1),_c('div',{staticClass:"col-3"},[_c('rwm-date-picker',{attrs:{"error":_vm.errors.startDate,"label":"Дата назначения"},on:{"blur":_vm.onBlur,"focus":function($event){return _vm.removeError('startDate')},"isError":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.setDateError.apply(void 0, [ 'startDate' ].concat( argsArray ))}},model:{value:(_vm._startDate),callback:function ($$v) {_vm._startDate=$$v},expression:"_startDate"}})],1),_c('div',{staticClass:"col-3"},[_c('rwm-date-picker',{attrs:{"error":_vm.errors.endDate,"label":"Дата окончания"},on:{"blur":_vm.onBlur,"focus":function($event){return _vm.removeError('endDate')},"isError":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.setDateError.apply(void 0, [ 'endDate' ].concat( argsArray ))}},model:{value:(_vm._endDate),callback:function ($$v) {_vm._endDate=$$v},expression:"_endDate"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }