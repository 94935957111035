<template>
  <label :class="['rwm-checkbox', {'error' : error && !checked}]" @click="$emit('click')">
    <p v-if="!linkText" class="rwm-checkbox__label" v-html="label"/>
    <p v-else class="rwm-checkbox__label">{{label}} <a @click.prevent.stop="$emit('downloadDU', linkTarget)">{{ linkText }}</a></p>
    <input v-bind="$attrs" type="checkbox" :checked="checked" @change="$emit('change', $event.target.checked)" @click="checkReadable($event)">
    <span class="checkmark"></span>
  </label>
</template>
<script>
export default {
  name: "RwmCheckbox",
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    checked: Boolean,
    label: String,
    error: {
      type: Boolean,
      default: false
    },
    linkText: String,
    linkTarget: Object
  },
  methods: {
    checkReadable(event) {
      if (this.readonly) {
        event.preventDefault();
      } else {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.rwm-checkbox {
  margin: 8px 0;
  display: block;
  position: relative;
  padding-left: 65px;
  max-width: max-content;
  cursor: pointer;
  font-weight: normal;
  font-size: 19px;
  line-height: 26px;
  user-select: none;
  color: $RWM-grey;
  text-align: left;
  &:hover,
  &:focus,
  &:active {
    color: $RWM-dark;
    & input ~ .checkmark { /* On mouse-over, add a grey background color */
      &:before {
        background-color: $RWM-grey-super-light;
        opacity: $RWM-opacity-hover;
      }
    }
  }
  &:hover input:checked ~ .checkmark { /* On mouse-over, add a grey background color */
    background-color: $RWM-dark;
    opacity: 1;
  }
  & input { /* Hide the browser's default checkbox */
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:disabled ~ .checkmark { //when checkbox is disabled
      background-color: $RWM-block;
    }
    &:checked ~ .checkmark { /* When the checkbox is checked, add a blue background */
      background-color: $RWM-grey;
      &:after { /* Show the checkmark when checked */
        display: block;
      }
    }
  }
  & .checkmark { /* Create a custom checkbox */
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    height: 33px;
    width: 33px;
    border: 2px solid $RWM-grey;
    border-radius: 5px;
    transition: all .3s;
    &:after { /* Create the checkmark/indicator (hidden when not checked) */
      content: "";
      position: absolute;
      display: none;

      left: 8px;
      top: 7px;
      width: 16px;
      height: 10px;
      border: solid $RWM-white;
      border-width: 3px 3px 0 0;
      transform: rotate(135deg);
    }
    &:before {
      content: "";
      box-sizing: content-box;
      position: absolute;
      top: 0; left: 0; bottom: 0; right: 0;
      transition: all .3s;
      background-color: transparent;
      opacity: 0;
    }
  }
  &.error {
    color: $RWM-red;
    &:hover {
      color: $RWM-dark-red;
    }
    & .checkmark {
      border: 2px solid $RWM-red;
    }
  }
}
</style>

<style lang="scss">
  .rwm-checkbox {
    &__label {
      & a {
        text-decoration: none;
        color: inherit;
        font-weight: 500;
        transition: color 300ms;
        position: relative;
        &:hover {
          color: $RWM-red;
        }
        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          height: 2px;
          background-color: currentColor;
          bottom: 1px;
        }
      }
    }
  }
</style>
