export default {
    methods:{
        show_sending_documents_message(message = "Документ отправлен.\n" + "После обработки документа Вам поступит уведомление.", timeout = 7000){
            this.$toast.success(message, {
                transition: "Vue-Toastification__bounce",
                maxToasts: 5,
                newestOnTop: true,
                timeout: timeout,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
            });
        }
    }

}