<template>
    <div class="foot">
        <div v-if="!footnotes" class="footnote" :id="`footnote-${footNumber}`">
            <div class="footnote-number"><p v-html="footNumber" /></div>
            <div class="footnote-text"><p v-html="footText" /></div>
        </div>
        <div v-else v-for="(note, index) in footnotes"
             :key="`note-${index+1}`"
             class="footnote" :id="`footnote-${index+1}`">
            <div class="footnote-number"><p v-html="index+1" /></div>
            <div class="footnote-text"><p v-html="note" /></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RwmFootnote",
        props: {
            footNumber: [String, Number],
            footText: String,
            footnotes: {
                type: Array,
                default: null
            }
        }
    }
</script>

<style scoped lang="scss">
    .foot {
        padding-top: 40px;
        border-top: 1px solid $RWM-grey-light;
        row-gap: 18px;
        display: flex;
        flex-direction: column;
        @media screen and (min-width: 961px) {
            display: none;
        }
        &note {
            display: flex;
            column-gap: 24px;
            color: $RWM-grey;

            &-number {
                display: flex;
                align-items: flex-start;
                top: 5px;
                position: relative;
            }
            &-text {
                font-weight: 500;
                font-size: 17px;
                line-height: 25px;
            }
            @media screen and (min-width: 961px) {
                display: none;
            }
        }
    }
</style>