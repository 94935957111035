<template>
    <div class="client-block">
        <div class="row" v-if="showDate">
            <div class="col-3">
                <rwm-date-picker :value="currentDate" label="Дата заполнения" disabled />
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <rwm-text-field
                        width="100%"
                        v-model="sname"
                        label="Фамилия"
                        disabled
                />
            </div>
            <div class="col-3">
                <rwm-text-field
                        width="100%"
                        v-model="name"
                        label="Имя"
                        disabled
                />
            </div>
            <div class="col-3">
                <rwm-text-field
                        width="100%"
                        v-model="mname"
                        label="Отчество"
                        disabled
                />
            </div>

        </div>
    </div>
</template>

<script>
    import RwmDatePicker from "../../../../../RWMDatePicker/RwmDatePicker";
    import RwmTextField from "../../../../../RWMTextField/RwmTextField";
    export default {
        name: "ClientBlock",
        components: {RwmTextField, RwmDatePicker},
        props: {
            sname: String,
            name: String,
            mname: String,
            showDate: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            currentDate() {
                return new Date()
            }
        },
        data() {
            return {
                // currentDate: ""
            }
        }
    }
</script>

<style scoped lang="scss">
    .client-block {
        display: flex;
        width: 100%;
        row-gap: 24px;
        flex-direction: column;
        &:after {
            content: "";
            opacity: 0.5;
            border: 1px solid $RWM-grey-light;
        }
    }
</style>
