<template>
    <div class="client-block">
        <p class="anketa">Документ, удостоверяющий личность: паспорт</p>
        <div class="row">
            <div class="col-3">
                <rwm-text-field
                        width="100%"
                        v-model="seria"
                        label="Серия паспорта"
                        disabled
                />
            </div>
            <div class="col-3">
                <rwm-text-field
                        width="100%"
                        v-model="numb"
                        label="Номер паспорта"
                        disabled
                />
            </div>
            <div class="col-3">
                <rwm-date-picker :value="issueDate" label="Дата выдачи паспорта" disabled />
            </div>
            <div class="col-3">
                <rwm-text-field
                        width="100%"
                        v-model="department"
                        label="Код подразделения"
                        disabled
                />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <rwm-text-field
                        width="100%"
                        v-model="issue"
                        label="Кем выдан паспорт"
                        disabled
                />
            </div>
        </div>
    </div>
</template>

<script>
    import RwmDatePicker from "../../../../../RWMDatePicker/RwmDatePicker";
    import RwmTextField from "../../../../../RWMTextField/RwmTextField";
    export default {
        name: "PassportBlock",
        components: {RwmTextField, RwmDatePicker},
        props: {
            department: String,
            issue: String,
            issueDate:String,
            number: String
        },
        computed: {
            seria() {
                return this.number.replace(/\s/g,'').substring(0, 4)
            },
            numb() {
                return this.number.replace(/\s/g,'').substring(4)
            }
        }
    }
</script>

<style scoped lang="scss">
    .client-block {
        display: flex;
        width: 100%;
        row-gap: 24px;
        flex-direction: column;
        &:after {
            content: "";
            opacity: 0.5;
            border: 1px solid $RWM-grey-light;
        }
    }
</style>