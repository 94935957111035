import { render, staticRenderFns } from "./RwmFootnote.vue?vue&type=template&id=2ea75224&scoped=true&"
import script from "./RwmFootnote.vue?vue&type=script&lang=js&"
export * from "./RwmFootnote.vue?vue&type=script&lang=js&"
import style0 from "./RwmFootnote.vue?vue&type=style&index=0&id=2ea75224&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ea75224",
  null
  
)

export default component.exports