<template>
  <div :class="`rwm-tooltip ${position}`">
    <div class="rwm-tooltip__container">
      <p class="rwm-text-fields" v-html="text" />
    </div>
  </div>
</template>

<script>
export default {
  name: "RwmToolTip",
  props: {
    text: {
      type: String,
      default: undefined
    },
    position: String
  },
}
</script>

<style scoped lang="scss">
.rwm-tooltip {
  position: absolute;
  top: 45px;
  padding: 16px;
  background-color: $RWM-white;
  border-radius: 5px;
  filter: drop-shadow(0px 1px 2px rgba(51, 63, 79, 0.32));
  z-index: 50;
  max-width: 580px;
  min-width: 550px;
  &.left {
    right: -12px;
    &:after{
      right: 16px;
    }
  }
  &.right {
    left: -12px;
    &:after{
      left: 16px;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: -7px;
    width: 17px;
    height: 17px;
    background-color: $RWM-white;
    transform: rotate(-45deg);
  }
}
</style>
