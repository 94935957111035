<template>
    <label :class="['rwm-radiobox', labelPosition]">
        <p class="rwm-radiobox__label">
            <span>{{label}}</span>
            <rwm-question-mark v-if="mark" :footnote-number="mark.footnoteNumber" :text="mark.text"/>
        </p>
        <input v-bind="$attrs" type="radio" :name="name" :value="value" :checked="checked"
               @change="$emit('change', value)">
        <span :class="['radiomark', labelPosition]"></span>
    </label>
</template>
<script>
    import RwmQuestionMark from "../RWMQutestionMark/RwmQuestionMark";

    export default {
        name: "RwmRadiobox",
        components: {RwmQuestionMark},
        model: {
            prop: 'checked',
            event: 'change'
        },
        props: {
            mark: {
                type: Object,
                default: null
            },
            checked: {
                type: Boolean,
                default: false
            },
            label: String,
            name: String,
            value: [String, Number, Boolean],
            labelPosition: {
                type: String,
                default: "right",
                validator: function (value) {
                    // Значение должно соответствовать одной из этих строк
                    return ['right', 'bottom'].indexOf(value) !== -1
                }
            }
        },
    }
</script>

<style scoped lang="scss">
    .rwm-radiobox {
        display: flex;
        align-items: center;
        position: relative;
        max-width: max-content;
        cursor: pointer;
        font-weight: normal;
        font-size: 22px;
        line-height: 30px;
        user-select: none;
        color: $RWM-grey;
        text-align: left;

        &__label {
            display: flex;

            & span {
                margin-right: 10px;
            }
        }

        &.right {
            padding-left: 65px;
            margin: 14px 0;
        }

        &.bottom {
            padding-left: 0;
            margin: 42px 0 0;
        }

        &:hover,
        &:focus,
        &:active {
            color: $RWM-dark;

            & input ~ .radiomark { /* On mouse-over, add a grey background color */
                border-color: $RWM-dark;
            }
        }

        &:hover input:checked ~ .radiomark { /* On mouse-over, add a grey background color */
            border-color: $RWM-dark;

            &:after {
                background-color: $RWM-dark;
            }
        }

        & input { /* Hide the browser's default checkbox */
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:not(:checked) ~ .radiomark {
                &:after {
                    opacity: 0;
                }
            }

            &:checked ~ .radiomark { /* When the checkbox is checked, add a blue background */
                &:after { /* Show the checkmark when checked */
                    opacity: 1;
                }
            }
        }

        & .radiomark { /* Create a custom checkbox */
            position: absolute;
            height: 32px;
            width: 32px;
            background-color: $RWM-white;
            border: 2px solid $RWM-grey;
            border-radius: 50%;
            transition: all .3s;

            &.right {
                top: 50%;
                transform: translate(0, -50%);
                left: 0;
            }

            &.bottom {
                top: auto;
                left: 50%;
                transform: translate(-50%, -45px);
            }

            &:after { /* Create the checkmark/indicator (hidden when not checked) */
                content: "";
                border-radius: 50%;
                width: 19px;
                height: 19px;
                box-sizing: content-box;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: all .3s;
                background-color: $RWM-grey;
            }
        }
    }
</style>
