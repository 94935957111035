<template>
  <rwm-text-field v-mask="Mask"
                  v-model="typed"
                  :label="label"
                  class="date-picker"
                  :error="error"
                  auto
                  @blur="blur"
                  :disabled="disabled"
                  :readonly="readonly">
    <div slot="append-icon">
      <input v-if="!disabled && !readonly" ref="u_date"
             type="date"
             :value="Value"
             @input.stop="calendar_changed($event.target.value)" >
      <v-icon :hovering="!disabled"
              :class="[{'errorMy' : error}]"
              :disabled="readonly || disabled">
        calendar
      </v-icon>
    </div>
  </rwm-text-field>
</template>

<script>
import RwmTextField from "@/components/RWMTextField/RwmTextField";
import VIcon from "@/components/icons/VIcon";
import moment from 'moment';
export default {
  name: "RwmDatePicker",
  components: {RwmTextField, VIcon},
  data() {
    return {
      typed: null,
      f: null,
    }
  },
  watch:{
    typed(val) {
      if(this.f !== null && this.f !== val){
        val = this.f;
        this.typed = val;
        this.f = null;
        return;
      }
      if(val && this.format && val.length === this.format.length){
        const dt = moment(val, this.format,true);
        this.$emit('isError', !dt.isValid());
        if(dt.isValid() && this.f !== null){
          this.blur(dt.format(this.format));
        }
      } else {
        this.$emit('isError', true);
      }
    },
    value(val) {
      const dt = moment(val, this.input_format,true);
      this.typed = dt.isValid() ? dt.format(this.format) : null;
      this.$emit('isError', !dt.isValid());
    },

  },
  props:{
    value: {
      type: [String, Number, Date],
      default: "",
    },
    label: {
      type:String,
      default: ""
    },
    error: {
      type: Boolean,
      default: null
    },
    disabled: {
      type: Boolean,
      default: null
    },
    readonly: {
      type: Boolean,
      default: null
    },
    output_format: {
      type: String,
      default: 'X'
    },
    format: {
      type:String,
      default: 'DD.MM.YYYY'
    },
    input_format:{
      type: String,
      default: 'X'
    }
  },
  computed: {
    Mask() {
      return this.format.replace(/[a-zA-Z0-9]/g, '#');
    },
    Value() {
      const dt = moment(this.value, this.input_format,true);
      return dt.isValid() ? dt.format('YYYY-MM-DD') : null;
    }
  },
  mounted() {
    if(this.value){
      const dt = moment(this.value, this.input_format,true);
      this.typed = dt.isValid() ? dt.format(this.format) : null;
      this.$emit('isError', !dt.isValid());
    }
  },
  methods: {
    blur(val = null){
      if(!this.readonly) {
        const dt = moment((val !== null && typeof val !== 'object') ? val : this.typed, this.format,true);
        if(!dt.isValid())
          this.typed = null;
        this.$emit('input', dt.isValid() ? dt.format(this.output_format) : null);
        this.$emit('isError', !dt.isValid());
        this.$emit('blur');
      }
    },
    calendar_changed(value){
      const dt = moment(value, 'YYYY-MM-DD', true);
      this.typed = dt.format(this.format);
      if(this.f !== true){
        this.f = dt.format(this.format);
      }
    },
  }
}
</script>
<style>
  .date-picker input[type=text]{
    z-index: 2 !important;
  }
</style>
<style scoped lang="scss">
.date-picker {
  & .append-icon {
    position: relative;
    z-index: 1;
    & input {
      position: absolute;
      opacity: 0;
      top: 0;
      right: 20px;
      bottom: 0;
      z-index: 1;
      &[type="date"]::-webkit-calendar-picker-indicator {
        font-size: 40px;
        cursor: pointer;
      }
    }
    & .errorMy {
      color: $RWM-dark-red;
    }
  }
}
</style>
