<template>
    <div class="client-block">
        <div class="row">
            <div class="col-3">
                <rwm-date-picker :value="birthday" label="Дата рождения" disabled />
            </div>
            <div class="col-3">
                <rwm-text-field
                        width="100%"
                        v-model="birthplace"
                        label="Место рождения"
                        disabled
                />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <rwm-text-field
                        width="100%"
                        v-model="registrationAddress"
                        label="Полный адрес регистрации"
                        disabled
                />
            </div>
            <div class="col-12">
                <rwm-text-field
                        width="100%"
                        v-model="actualAddress"
                        label="Полный адрес проживания"
                        disabled
                />
            </div>
        </div>
        <div class="row">
            <div class="col-3" v-if="isNationality">
                <rwm-text-field
                        width="100%"
                        v-model="nationality"
                        label="Гражданство"
                        disabled
                />
            </div>
            <div class="col-3" v-if="inn!==null">
                <rwm-text-field
                        width="100%"
                        v-model="inn"
                        label="ИНН"
                        disabled
                />
            </div>
            <div class="col-3" v-if="snils!==null">
                <rwm-text-field
                        width="100%"
                        v-model="snils"
                        label="СНИЛС"
                        disabled
                />
            </div>
            <div class="col-3">
                <rwm-text-field
                        width="100%"
                        v-model="phone"
                        label="Телефон"
                        disabled
                />
            </div>
            <div class="col-3">
                <rwm-text-field
                        width="100%"
                        v-model="email"
                        label="E-mail"
                        disabled
                />
            </div>
        </div>
    </div>
</template>

<script>
    import RwmDatePicker from "../../../../../RWMDatePicker/RwmDatePicker";
    import RwmTextField from "../../../../../RWMTextField/RwmTextField";
    export default {
        name: "AddressBlock",
        components: {RwmTextField, RwmDatePicker},
        props: {
            birthday: String,
            birthplace: String,
            registrationAddress:String,
            actualAddress: String,
            inn: [String, null],
            snils: [String, null],
            phone: String,
            email: String,
            isNationality: {
                type:Boolean,
                default: true
            }
        },
        computed: {
            nationality() {
                return this.inn ? "Российская Федерация" : null
            }
        }
    }
</script>

<style scoped lang="scss">
    .client-block {
        display: flex;
        width: 100%;
        row-gap: 24px;
        flex-direction: column;
        &:after {
            content: "";
            opacity: 0.5;
            border: 1px solid $RWM-grey-light;
        }
    }
</style>